<script>
import { defineComponent } from 'vue';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import { executeCustomModuleCall } from '@/services/api/module.api';
import {
  DHME_M_ASSEMBLY_HALL_DASHBOARD,
  DHME_M_STATION_DASHBOARD,
} from '@/modules/modules';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'CancelModuleDialog',
  mixins: [InputRulesMixin],
  props: {
    moduleTask: {
      type: Object,
      required: true,
    },
    displayed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      isLoading: false,
      agvStatus: null,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      moduleId: 'dhmeAssemblyHallDashboardStore/moduleId',
      focusedHall: 'dhmeAssemblyHallDashboardStore/focusedHall',
    }),
    showDialog() {
      return this.displayed;
    },
    stationDashboardModuleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_M_STATION_DASHBOARD
      ).id;
    },
  },
  methods: {
    closeDialog() {
      this.agvStatus = null;
      this.$emit('closeDialog');
    },
    async cancelModule() {
      let task = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'cancelModuleTask',
        {
          task: this.moduleTask.id,
          hall: this.focusedHall.id,
          stationDashboardModule: this.stationDashboardModuleId,
          AGV: this.agvStatus,
        }
      );
      this.$emit('canceledModule', task);
      this.closeDialog();
    },
  },
});
</script>

<template>
  <v-dialog
    v-model="showDialog"
    max-width="600px"
    persistent
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title
        class="justify-center text-uppercase headline text-break text-center"
      >
        Are you sure you want to cancel this module {{ moduleTask.sbscode }}?
      </v-card-title>
      <v-divider />

      <div class="px-10 py-2 d-flex align-center">
        <v-card-subtitle> AGV status </v-card-subtitle>
        <v-spacer />
        <v-radio-group v-model="agvStatus" row>
          <v-radio value="active" label="Active" />
          <v-radio value="inactive" label="Inactive" />
        </v-radio-group>
      </div>
      <v-card-actions class="ant-dialog-actions-bg ant-border-top">
        <v-spacer />
        <v-btn
          color="error"
          text
          small
          :disabled="isLoading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          small
          :disabled="!agvStatus"
          :loading="isLoading"
          @click="cancelModule"
        >
          Cancel Module
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
