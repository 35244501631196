var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"single-trestle-container",class:{ large: _vm.isLarge },style:({
    opacity: _vm.selectedTrestle ? (_vm.selectedTrestle === _vm.trestle ? 1 : 0.3) : 1,
  })},[_c('div',{staticClass:"pa-1 flex-1 d-flex flex-column",staticStyle:{"border-left":"dashed 1px #6b6b6b","border-right":"dashed 1px #6b6b6b"}},_vm._l((_vm.trestle.positions),function(position,positionIndex){return _c('div',{key:positionIndex,staticClass:"d-flex flex-1"},[(position.elements.length === 0)?_c('div',{staticClass:"flex-1 mb-1 d-flex align-center px-1",staticStyle:{"border":"dotted 1px #6b6b6b","background":"transparent","height":"25px"}}):_vm._l((position.elements),function(element,elementIndex){return _c('v-tooltip',{key:elementIndex,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"flex-1 mb-1 d-flex align-center px-1 c-pointer",class:{
              'mx-1': elementIndex < position.elements.length,
            },style:({
              border: 'solid 1px #6b6b6b',
              background: _vm.typeColorDictionary[element?.module_type],
              opacity:
                _vm.selectedModule !== null
                  ? _vm.selectedModule === element?.module_id
                    ? 1
                    : 0.3
                  : 1,
            }),on:{"click":function($event){return _vm.$emit('clickedElement', element)}}},on),[_c('div',{staticClass:"d-flex justify-start fs-12"},[_vm._v(" "+_vm._s(positionIndex + 1)+"."+_vm._s(elementIndex + 1)+" ")]),(element?.production_id)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check-circle")]):_vm._e(),_c('div',{staticClass:"flex-1 d-flex justify-end"},[_vm._v(" "+_vm._s(element.element_type ? element.element_type : '-')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(element.element_id))])])})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }