<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Assembly Hall Dashboard">
      <template #module-nav-logo>
        <img
          v-if="isDaiwaLicense"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div v-if="status === 'success'" class="d-flex flex-1 overflow-hidden">
      <div
        class="px-5 py-2 background-white ant-border-right"
        style="max-width: 270px"
      >
        <ant-input label="Hall" style="margin-top: 0 !important">
          <template #input-field>
            <v-select
              v-model="selectedHall"
              :items="halls"
              item-value="id"
              item-text="title"
              placeholder="Hall"
              hide-details
              return-object
              filled
              dense
              @input="setHall"
            >
              <template #item="{ item }">
                {{ item.title }}
                <v-spacer />
                <v-chip class="mr-2" small>
                  <v-icon left small
                    >{{
                      item.automated
                        ? 'mdi-robot-industrial'
                        : 'mdi-account-wrench'
                    }}
                  </v-icon>
                  {{ item.automated ? 'Automated' : 'Manual' }}
                </v-chip>
              </template>
            </v-select>
          </template>
        </ant-input>
        <v-list dense>
          <v-list-item-group
            v-model="assemblyHallNavigation"
            color="primary"
            mandatory
          >
            <v-list-item
              link
              :disabled="!selectedHall"
              @click="
                $router.push({
                  name: `${DHME_M_ASSEMBLY_HALL_DASHBOARD()}-planning`,
                  params: {
                    hall: selectedHall.id,
                  },
                })
              "
            >
              <v-list-item-icon>
                <v-icon>mdi-calendar-multiselect</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Planning (modules)</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :disabled="!selectedHall"
              @click="
                $router.push({
                  name: `${DHME_M_ASSEMBLY_HALL_DASHBOARD()}-trestles`,
                  params: {
                    hall: selectedHall.id,
                  },
                })
              "
            >
              <v-list-item-icon>
                <v-icon class="mdi-rotate-270">mdi-format-list-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Trestles</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="isSuperAdmin"
              link
              :disabled="!selectedHall"
              @click="
                $router.push({
                  name: `${DHME_M_ASSEMBLY_HALL_DASHBOARD()}-mes`,
                  params: {
                    hall: selectedHall.id,
                  },
                })
              "
            >
              <v-list-item-icon>
                <v-icon>mdi-server</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>MES</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <router-view v-if="selectedHall" class="flex-1"></router-view>
    </div>
    <div v-else class="d-flex justify-center align-center full-height">
      <ant-loading />
    </div>
  </div>
</template>

<script>
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { mapGetters } from 'vuex';
import { DHME_M_ASSEMBLY_HALL_DASHBOARD } from '@/modules/modules';
import AntLoading from '@/components/AntLoading.vue';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'DHMEMAssemblyHallOverview',
  components: { AntInput, AntLoading, ModuleNavigationBar },
  data: () => {
    return {
      assemblyHallNavigation: null,
      selectedHall: null,
      hallProjects: [],
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      status: 'dhmeAssemblyHallDashboardStore/status',
      halls: 'dhmeAssemblyHallDashboardStore/halls',
      focusedHall: 'dhmeAssemblyHallDashboardStore/focusedHall',
      isSuperAdmin: 'isSystemAdmin',
      isDaiwaLicense: 'isDaiwaLicense',
    }),
    moduleId() {
      return (
        this.project.modules.find(
          (module) => module.route === DHME_M_ASSEMBLY_HALL_DASHBOARD
        )?.id ?? null
      );
    },
  },
  watch: {
    focusedHall(value) {
      this.selectedHall = { ...value };
    },
  },

  async mounted() {
    this.$store.commit(
      'dhmeAssemblyHallDashboardStore/set_module_id',
      this.moduleId
    );
    await this.$store.dispatch('dhmeAssemblyHallDashboardStore/fetchHalls');

    if (this.$route.params.hall) {
      const hall = this.halls.find((h) => h.id === this.$route.params.hall);
      await this.$store.dispatch(
        'dhmeAssemblyHallDashboardStore/selectAssemblyHall',
        hall
      );

      switch (this.$route.name) {
        case `${DHME_M_ASSEMBLY_HALL_DASHBOARD}-planning`:
          this.assemblyHallNavigation = 0;
          break;
        case `${DHME_M_ASSEMBLY_HALL_DASHBOARD}-trestles`:
          this.assemblyHallNavigation = 1;
          break;
        case `${DHME_M_ASSEMBLY_HALL_DASHBOARD}-mes`:
          this.assemblyHallNavigation = 2;
          break;
      }
    }
  },

  methods: {
    DHME_M_ASSEMBLY_HALL_DASHBOARD() {
      return DHME_M_ASSEMBLY_HALL_DASHBOARD;
    },
    setHall(value) {
      this.$store.dispatch(
        'dhmeAssemblyHallDashboardStore/selectAssemblyHall',
        value
      );
      this.$router.push({
        name: `${DHME_M_ASSEMBLY_HALL_DASHBOARD}-planning`,
        params: { hall: value.id },
      });
      this.assemblyHallNavigation = 0;
    },
  },
};
</script>

<style scoped></style>
