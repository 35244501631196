<script>
import { defineComponent } from 'vue';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import { executeCustomModuleCall } from '@/services/api/module.api';
import {
  DHME_M_ASSEMBLY_HALL_DASHBOARD,
  DHME_M_STATION_DASHBOARD,
} from '@/modules/modules';
import { mapGetters } from 'vuex';
import AntInput from '@/components/AntInput.vue';
import moment from 'moment';

export default defineComponent({
  name: 'LinkTactDialog',
  components: { AntInput },
  mixins: [InputRulesMixin],
  props: {
    task: {
      type: Object,
      required: true,
    },
    displayed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      isLoading: false,
      tacts: [],
      tact: null,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      focusedHall: 'dhmeAssemblyHallDashboardStore/focusedHall',
    }),
    showDialog() {
      return this.displayed;
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_M_ASSEMBLY_HALL_DASHBOARD
      ).id;
    },
    stationDashboardModuleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_M_STATION_DASHBOARD
      ).id;
    },
  },
  watch: {
    async displayed(val) {
      if (val) {
        this.tacts = await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'fetchTacts',
          {
            hall: this.focusedHall.id,
          }
        );
      }
    },
  },
  methods: {
    closeDialog() {
      this.tact = null;
      this.$emit('closeDialog');
    },
    async linkTact() {
      await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'linkTaskToTact',
        {
          task: this.task.id,
          tact: this.tact,
        }
      );
      this.$store.commit('showNotification', {
        content: 'Tact linked successfully',
        color: 'success',
      });
      this.task.task_type.custom_9 = this.tact;
      this.closeDialog();
    },

    getDateTimeFromTimestamp(timestamp) {
      return moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss');
    },
  },
});
</script>

<template>
  <v-dialog
    v-model="showDialog"
    max-width="600px"
    persistent
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title
        class="justify-center text-uppercase headline text-break text-center"
      >
        Link task to tact
      </v-card-title>
      <v-divider />

      <div class="px-10 py-2 d-flex align-center">
        <ant-input label="Tact" class="flex-1">
          <template #input-field>
            <v-autocomplete
              v-model="tact"
              :items="tacts"
              item-value="id"
              item-text="mes_id"
              placeholder="Tact Id"
              filled
            >
              <template #item="{ item }">
                <div class="d-flex full-width">
                  <div>{{ item.mes_id }}</div>
                  <v-spacer />
                  <div>{{ getDateTimeFromTimestamp(item.tact_start) }}</div>
                </div>
              </template>
            </v-autocomplete>
          </template>
        </ant-input>
      </div>
      <v-card-actions class="ant-dialog-actions-bg ant-border-top">
        <v-spacer />
        <v-btn
          color="error"
          text
          small
          :disabled="isLoading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          small
          :disabled="!tact"
          :loading="isLoading"
          @click="linkTact"
        >
          Link
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
