<template>
  <div class="flex-1 d-flex overflow-x-auto">
    <div
      v-for="(lane, laneIndex) in assemblyLanes"
      :key="laneIndex"
      class="d-flex flex-column ant-border-right flex-1"
    >
      <div class="ant-glass-background radius-0 ant-border-bottom d-flex">
        <v-subheader
          ><v-chip :color="lane.color">{{ lane.title }}</v-chip></v-subheader
        >
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div class="d-flex mx-2 align-center" v-on="on">
              <v-icon>mdi-cube-outline</v-icon>
              {{
                lane.trestles
                  .flatMap((trestle) => trestle.positions)
                  .filter((item) => !item.isEmpty).length
              }}
            </div>
          </template>
          <span>Elements</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div class="d-flex mx-2 align-center" v-on="on">
              <v-icon class="mdi-rotate-270">mdi-format-list-group</v-icon>
              {{ lane.trestles.length }}
            </div>
          </template>
          <span>Trestles</span>
        </v-tooltip>
      </div>
      <div
        v-if="selectedProject"
        class="flex-1 mx-2 overflow-y-auto pa-4 d-flex flex-column"
      >
        <div
          v-for="(trestle, index) in lane.trestles"
          :key="index"
          class="d-flex ant-border-bottom pb-2 mb-2"
        >
          <div class="d-flex align-center justify-center">
            {{ index + 1 }}
          </div>
          <div class="d-flex flex-column flex-1 align-end mr-2">
            <v-tooltip
              v-for="module in [
                ...new Set(
                  trestle.positions
                    .flatMap((p) => p.elements)
                    .map((e) => e?.module_id)
                ),
              ]"
              :key="module"
              left
            >
              <template #activator="{ on }">
                <v-chip
                  small
                  :color="getModuleColor(module, trestle)"
                  class="mb-1"
                  :style="{
                    opacity:
                      selectedModule !== null
                        ? selectedModule === module
                          ? 1
                          : 0.3
                        : 1,
                  }"
                  @click="
                    selectedModule === module
                      ? (selectedModule = null)
                      : (selectedModule = module)
                  "
                  v-on="on"
                  >{{ module }}</v-chip
                >
              </template>
              <span>{{ getTypeByModuleId(module, trestle) }}</span>
            </v-tooltip>
          </div>
          <base-dhme-double-trestle
            :type-color-dictionary="typeColorDictionary"
            :trestle="trestle"
            :selected-module="selectedModule"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { executeCustomModuleCall } from '@/services/api/module.api';
import {
  createModuleTypeColorDictionary,
  getModuleColor,
  getModuleTypeByElements,
} from '@/modules/daiwa-house-modular-europe/AssemblyHallDashboard/assembly-hall-trestles.utils';
import BaseDhmeDoubleTrestle from '@/components/Modules/Daiwa-House-Modular-Europe/Base/BaseDhmeDoubleTrestle.vue';
import { generateManualVirtualTrestles } from '@/services/dhme/trestles-helper';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';

export default {
  name: 'ManualTrestleOverview',
  components: { BaseDhmeDoubleTrestle },
  props: {
    selectedProject: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      assemblyLanes: [],
      assemblyLocations: [],
      typeColorDictionary: {},
      modules: [],
      selectedModule: null,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      focusedHall: 'dhmeAssemblyHallDashboardStore/focusedHall',
      moduleId: 'dhmeAssemblyHallDashboardStore/moduleId',
      lanes: 'dhmeAssemblyHallDashboardStore/hallLanes',
    }),
  },
  watch: {
    async assemblyLocations(value) {
      if (value.length > 0 && this.selectedProject) {
        await this.fetchModulesAndElements();
      }
    },
    selectedProject: {
      immediate: true,
      async handler(value) {
        this.assemblyLocations = await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'fetchHallLocations',
          {
            hall: this.focusedHall.id,
            project: value.id,
          }
        );

        const { lanes } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_ASSEMBLY_LANES',
              project: this.project.id,
              as: 'lanes',
              sortBy: 'order',
              records: this.assemblyLocations.map((x) => x.assembly_lane),
              columns: [
                {
                  name: 'title',
                },
                {
                  name: 'color',
                },
                {
                  name: 'order',
                },
              ],
            },
          ],
        });

        lanes.records.forEach((lane) => {
          this.assemblyLanes.push({
            ...lane,
            trestles: [],
          });
        });
        if (value && this.assemblyLocations.length > 0) {
          await this.fetchModulesAndElements();
        }
      },
    },
  },
  async mounted() {},
  methods: {
    async fetchModulesAndElements() {
      this.$emit('changeLoadingState', true);
      this.modules = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'fetchModulesForProject',
        {
          project: this.selectedProject.id,
        }
      );
      this.createTypeColorDictionary();
      this.createVirtualTrestles();
    },
    getModuleColor(moduleId, trestle) {
      return getModuleColor(moduleId, trestle, this.typeColorDictionary);
    },
    getTypeByModuleId(moduleId, trestle) {
      return getModuleTypeByElements(moduleId, trestle);
    },
    createTypeColorDictionary() {
      this.typeColorDictionary = createModuleTypeColorDictionary(this.modules);
    },
    createVirtualTrestles() {
      this.assemblyLanes.forEach((lane) => {
        let locationsByLane = this.assemblyLocations.filter(
          (l) => l.assembly_lane === lane.id
        );
        lane.trestles = generateManualVirtualTrestles(
          this.modules.filter((m) =>
            locationsByLane.some((l) => l.id === m.assembly_location)
          )
        );
      });
      this.$emit('changeLoadingState', false);
    },
  },
};
</script>

<style scoped lang="scss">
.double-trestle-container {
  width: 200px;
  border-bottom: solid 2px #6b6b6b;
  border-top: solid 2px #6b6b6b;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.75);

  .manual-trestle-separator {
    background-color: #6b6b6b;
    height: 10px;
    width: 100%;
  }
}
</style>
