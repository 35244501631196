var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 d-flex overflow-x-auto"},_vm._l((_vm.assemblyLanes),function(lane,laneIndex){return _c('div',{key:laneIndex,staticClass:"d-flex flex-column ant-border-right flex-1"},[_c('div',{staticClass:"ant-glass-background radius-0 ant-border-bottom d-flex"},[_c('v-subheader',[_c('v-chip',{attrs:{"color":lane.color}},[_vm._v(_vm._s(lane.title))])],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"d-flex mx-2 align-center"},on),[_c('v-icon',[_vm._v("mdi-cube-outline")]),_vm._v(" "+_vm._s(lane.trestles .flatMap((trestle) => trestle.positions) .filter((item) => !item.isEmpty).length)+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Elements")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"d-flex mx-2 align-center"},on),[_c('v-icon',{staticClass:"mdi-rotate-270"},[_vm._v("mdi-format-list-group")]),_vm._v(" "+_vm._s(lane.trestles.length)+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Trestles")])])],1),(_vm.selectedProject)?_c('div',{staticClass:"flex-1 mx-2 overflow-y-auto pa-4 d-flex flex-column"},_vm._l((lane.trestles),function(trestle,index){return _c('div',{key:index,staticClass:"d-flex ant-border-bottom pb-2 mb-2"},[_c('div',{staticClass:"d-flex align-center justify-center"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"d-flex flex-column flex-1 align-end mr-2"},_vm._l(([
              ...new Set(
                trestle.positions
                  .flatMap((p) => p.elements)
                  .map((e) => e?.module_id)
              ),
            ]),function(module){return _c('v-tooltip',{key:module,attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticClass:"mb-1",style:({
                  opacity:
                    _vm.selectedModule !== null
                      ? _vm.selectedModule === module
                        ? 1
                        : 0.3
                      : 1,
                }),attrs:{"small":"","color":_vm.getModuleColor(module, trestle)},on:{"click":function($event){_vm.selectedModule === module
                    ? (_vm.selectedModule = null)
                    : (_vm.selectedModule = module)}}},on),[_vm._v(_vm._s(module))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeByModuleId(module, trestle)))])])}),1),_c('base-dhme-double-trestle',{attrs:{"type-color-dictionary":_vm.typeColorDictionary,"trestle":trestle,"selected-module":_vm.selectedModule}})],1)}),0):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }