import ColorHelper from '@/services/color-helper';

export function getModuleColor(moduleId, trestle, colorDict) {
  let elements = trestle.positions.flatMap((p) => p.elements);
  if (elements.length > 0) {
    return colorDict[
      elements.find((e) => e.module_id === moduleId).module_type
    ];
  }
  return '';
}

export function getModuleTypeByElements(moduleId, trestle) {
  let elements = trestle.positions.flatMap((p) => p.elements);
  if (elements.length > 0) {
    return elements.find((e) => e.module_id === moduleId).module_type;
  }
  return '';
}

export function createModuleTypeColorDictionary(modules) {
  let dict = {};
  let types = [
    ...new Set(
      modules
        .flatMap((module) => module.elements)
        .map((element) => element.module_type)
    ),
  ];
  types.forEach((type) => {
    dict[type] = ColorHelper.generateLightColorHex();
  });

  return dict;
}
