const defaultLength = 6000;

export function generateAutomatedVirtualTrestlesByPosition(modules, positions) {
  let trestles = [];

  // Function to create a fresh trestle with 10 positions
  const createEmptyTrestle = () => ({
    positions: Array.from({ length: 5 }, () => ({
      elements: [],
      length: defaultLength,
    })),
  });

  let trestle = createEmptyTrestle();

  let modulesWithElements = modules.filter(
    (module) => module.elements.length > 0
  );

  modulesWithElements.forEach((module, index) => {
    let positionIndex = 0;

    let elements = module.elements.filter(
      (element) =>
        positions.includes(element.element_position.charAt(0)) &&
        element.element_position.charAt(1) === '.'
    );

    elements.forEach((element) => {
      if (element.length === null) {
        element.length = defaultLength;
      }
      element.onTrestle = false;
      while (!element.onTrestle) {
        if (trestle.positions[positionIndex].length - element.length >= 0) {
          trestle.positions[positionIndex].elements.push(element);
          trestle.positions[positionIndex].length -= element.length;
          element.onTrestle = true;
        } else {
          positionIndex++;
          if (positionIndex === 5) {
            trestles.push(trestle);
            trestle = createEmptyTrestle();
            positionIndex = 0;
          }
        }
      }
    });
  });
  for (let i = 0; i < trestle.positions.length; i++) {
    if (trestle.positions[i].elements.length === 0) {
      trestle.positions.splice(i, 1);
      trestle.positions.unshift({
        elements: [],
        length: defaultLength,
      });
    }
  }

  trestles.push(trestle);

  return trestles;
}

export function generateManualVirtualTrestles(modules) {
  const defaultLength = 6000; // Adjust as needed
  const positionsPerTrestle = 10; // Number of positions in a trestle

  // Function to create a trestle with default positions
  const createEmptyTrestle = () => ({
    positions: Array.from({ length: positionsPerTrestle }, () => ({
      elements: [],
      length: defaultLength,
    })),
  });

  // Filling order: fill positions from outside to inside on one side first
  const fillingOrder = Array.from({ length: positionsPerTrestle }, (_, i) => i);

  const trestles = [];
  let trestle = createEmptyTrestle();
  let positionIndex = 0;

  const modulesWithElements = modules.filter(
    (module) => module.elements && module.elements.length > 0
  );

  for (const [moduleIndex, module] of modulesWithElements.entries()) {
    // Sort elements by assembly_sequence to maintain correct order
    module.elements.sort((a, b) => a.assembly_sequence - b.assembly_sequence);

    // Check if current trestle has enough positions for this module
    const positionsRemaining = positionsPerTrestle - positionIndex;
    if (module.elements.length > positionsRemaining) {
      trestles.push(trestle);
      trestle = createEmptyTrestle();
      positionIndex = 0;
    }

    for (const element of module.elements) {
      // Assign default length if not set
      if (!element.length) {
        element.length = defaultLength;
      }

      // Check if the element fits in the default position length
      if (element.length > defaultLength) {
        throw new Error(
          `Element with length ${element.length} is too big to fit in any position.`
        );
      }

      const positionIdx = fillingOrder[positionIndex];
      trestle.positions[positionIdx].elements.push(element);
      trestle.positions[positionIdx].length -= element.length;
      element.onTrestle = true;

      positionIndex++;
    }

    // If this is the last module, push the remaining trestle
    if (moduleIndex === modulesWithElements.length - 1) {
      trestles.push(trestle);
    }
  }

  return trestles;
}
